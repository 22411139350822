import React from 'react'

function OrdersPage() {
  return (
    <div>
      OrdersPage
    </div>
  )
}

export default OrdersPage
