import React from 'react';
import { Helmet } from 'react-helmet';
import OrderSuccess from '../Components/OrderSuccess/OrderSuccess';

function OrderSuccessPage() {

    return (
        <div>
            <Helmet>
                <title>Order Success | Shuzo</title>
                <meta name="description" content='' />
                {/* Add more meta tags as needed */}
            </Helmet>
            <OrderSuccess  />
        </div>
    );
}

export default OrderSuccessPage;
