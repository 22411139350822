import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyB4ZwyI8TsdqGdP6hvLT71TfnH0MGidKHY",
  authDomain: "test-e6c6e.firebaseapp.com",
  databaseURL: "https://test-e6c6e-default-rtdb.firebaseio.com",
  projectId: "test-e6c6e",
  storageBucket: "test-e6c6e.appspot.com",
  messagingSenderId: "412674510366",
  appId: "1:412674510366:web:f28beb7329009774f4280f",
  measurementId: "G-02C5ZMM8FP"
};



// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();
const FieldValue = firebase.firestore.FieldValue; // Exporting FieldValue

export { firestore, FieldValue }; // Exporting firestore and FieldValue