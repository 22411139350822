import React, { useState, useEffect } from 'react';
import { FaTimes, FaFilter } from 'react-icons/fa';
import ProductCard from '../ProductCard';
import ProductDetails from '../ProductDetails';
import products from '../../data.json';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

export default function AllProducts() {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showSidebar, setShowSidebar] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6);

    useEffect(() => {
        const handleResize = () => {
            setShowSidebar(window.innerWidth >= 1024);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleCardClick = (product) => {
        setSelectedProduct(product);
    };

    const categories = ['All', ...new Set(products.map(product => product.category))];

    const handleCategoryClick = (category) => {
        setSelectedCategory(category === 'All' ? null : category);
        if (window.innerWidth < 1024) {
            setShowSidebar(false);
        }
    };

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selectedCategory ? products.filter(product => product.category === selectedCategory).slice(indexOfFirstItem, indexOfLastItem) : products.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const nextPage = () => setCurrentPage(currentPage + 1);

    const prevPage = () => setCurrentPage(currentPage - 1);

    return (
        <div className="bg-white mx-auto container px-4 py-16  flex relative">
            <div className={`lg:w-1/4 ${showSidebar ? 'block h-full' : 'hidden'} container mx-auto px-8 w-full fixed top-0 left-0 lg:h-full h-full  bg-white z-30 overflow-y-auto  lg:sticky lg:top-16`}>
                <div>
                    <div className="flex justify-between bg-black text-white py-2 px-2 rounded-md items-center mb-4 mt-24">
                        <h3 className="font-semibold text-lg">Categories</h3>
                        <button onClick={toggleSidebar} className="lg:hidden text-gray-500 hover:text-gray-700 focus:outline-none">
                            <FaTimes />
                        </button>
                    </div>
                    <ul>
                        {categories.map(category => (
                            <li key={category} className="mb-2">
                                <button
                                    className={`text-black hover:underline px-2 border-b text-lg border-black w-full flex justify-start ${selectedCategory === category ? 'font-bold' : ''}`}
                                    onClick={() => handleCategoryClick(category)}
                                >
                                    {category}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="w-full lg:w-3/4 px-4 sm:px-6">
                <div className="text-center">
                    <h2 className="text-black font-bold text-[32px]  md:text-[44px] lg:text-[64px] ">All Products</h2>
                    <div className="flex justify-center">
                        <p className="max-w-[600px] text-[16px] px-2">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam voluptas tenetur nemo consequuntur veritatis modi. </p>
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-1 md:grid-cols-2 mt-10 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8">
                    {currentItems.map((product) => (
                        <ProductCard key={product.id} product={product} onCardClick={handleCardClick} />
                    ))}
                </div>
                <div className="flex justify-center mt-8">
                    <button onClick={prevPage} disabled={currentPage === 1} className="mr-2 px-2 py-2 bg-black text-white rounded-full  cursor-pointer"><MdChevronLeft className='w-6 h-6' />
                    </button>
                    <button onClick={nextPage} disabled={currentItems.length < itemsPerPage} className="ml-2 px-2 py-2 bg-black text-white rounded-full  cursor-pointer"><MdChevronRight className='w-6 h-6' /></button>
                </div>
            </div>

            <div className="lg:hidden fixed bottom-4 right-4">
                <button onClick={toggleSidebar} className="bg-black text-white px-4 py-2 rounded-full py-4 shadow-md hover:bg-gray-600">
                    <FaFilter />
                </button>
            </div>

            {selectedProduct && <ProductDetails product={selectedProduct} />}
        </div>
    );
}
