import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../../success.json'; // Replace 'your-animation.json' with the path to your JSON file

const OrderSuccess = () => {
    const location = useLocation();
    const { orderId, cartItems } = location.state;
    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    // Calculate the subtotal
    const totalPrice = cartItems.reduce((acc, curr) => acc + (curr.price * curr.quantity), 0);

    // Shipping cost
    const shippingCost = 200;

    // Calculate the total amount
    const totalAmount = totalPrice + shippingCost;

    // Options for the Lottie animation
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []); // Empty dependency array ensures this effect runs only once when the component mounts

    // Function to clear all data and navigate to the home page
    const handleGoHome = () => {
        // Clear all data here
        // For example, you can use localStorage.clear() to clear all data from localStorage
        localStorage.clear();
        // Navigate to the home page
        navigate('/');
        // Reload the page to ensure a fresh start
        window.location.reload();
    };
    return (
        <div className="max-w-2xl mx-auto px-4 py-8">

            <div className="bg-white shadow-md rounded-lg overflow-hidden py-4">
                <div className="text-center">
                    <Lottie
                        options={defaultOptions}
                        height={200}
                        width={200}
                    />
                    <h1 className="text-3xl font-bold mb-4">Order Placed Successfully!</h1>

                </div>
                <div className="p-4">
                    <p className="text-lg text-center underline font-bold">Order ID: {orderId}</p>
                    <p className='text-center'>Save this order Id for order tracking</p>
                    <div className="mt-4">
                        <h2 className="text-lg font-semibold">Ordered Products:</h2>
                        <ul className="mt-2 max-h-[500px] overflow-y-auto">
                            {cartItems.map((product) => (
                                <li key={product.id} className="flex py-6">
                                    <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                        <img
                                            src={product.imageSrc}
                                            alt={product.imageAlt}
                                            className="h-full w-full object-cover object-center"
                                        />
                                    </div>
                                    <div className="ml-4 flex flex-1 flex-col">
                                        <div>
                                            <div className="flex justify-between text-base font-medium text-gray-900">
                                                <h3>
                                                    <a href={product.href}>
                                                        {product.name}
                                                    </a>
                                                </h3>
                                                <p className="ml-4">{product.price} PKR</p>
                                            </div>
                                            <p className="mt-1 text-sm text-gray-500">
                                                {product.color}
                                            </p>
                                            <p className="mt-1 text-sm text-gray-500">
                                                {product.size}
                                            </p>
                                        </div>
                                        <div className="flex flex-1 items-end justify-between text-sm">
                                            <p className="text-gray-500">
                                                Qty {product.quantity}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="mt-8">
                        <div className="flex justify-between text-base font-medium text-gray-900">
                            <p>Subtotal</p>
                            <p>{totalPrice} PKR</p>
                        </div>
                        <div className="flex justify-between text-base font-medium text-gray-900 mt-5">
                            <p>Shipping</p>
                            <p>{shippingCost} PKR</p>
                        </div>
                        <hr className="my-6 border sm:mx-auto lg:my-8" />
                        <div className="flex justify-between text-xl font-bold text-gray-900 mt-10">
                            <p>Total</p>
                            <p>{totalAmount} PKR</p>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center'>


                    <button
                        onClick={handleGoHome}
                        className="text-white text-md font-medium bg-black w-48 h-18 rounded-md duration-150 ease-in-out py-3 px-4 mt-3 md:mt-0 hover:bg-gray-600"
                    >
                        Go Home
                    </button>

                </div>
            </div>
        </div>
    );
};

export default OrderSuccess;
