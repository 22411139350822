import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import ProductDetails from '../Components/ProductDetails';

function ProductDetailsPage() {
    // Access the route parameters
    const { productId, productName } = useParams();

    return (
        <div>
            <Helmet>
                <title>{` ${productName} | Shuzo`}</title>
                <meta name="description" content={`Details of ${productName} on Shuzo`} />
                {/* Add more meta tags as needed */}
            </Helmet>
            <ProductDetails productId={productId} />
        </div>
    );
}

export default ProductDetailsPage;
