// Preloader.js
import React from 'react';
import { MoonLoader } from 'react-spinners';



const GroupLoader = () => {
    return (
        <div className="">
            <MoonLoader color="#000" />
        </div>
    );
};

export default GroupLoader;
