import React from 'react';
import { Helmet } from 'react-helmet';

import Checkout from '../Components/Checkout/Checkout';

function CheckoutPage() {
  return (
    <div>
      <Helmet>
        <title> Checkout | Shuzo  </title>
        <meta name="description" content="Description of your website" />

      </Helmet>
      <Checkout />
    </div>
  );
}

export default CheckoutPage;
