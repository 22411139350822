import React from "react";
import { Helmet } from "react-helmet";
import AllProducts from "../Components/Products/AllProducts";

function Products() {
  return (
    <div>
      <Helmet>
        <title>All Products | Shuzo</title>
        {/* Add more meta tags as needed */}
      </Helmet>
      <AllProducts />
    </div>
  );
}
export default Products;
