import { Link } from "react-router-dom";
import ProductCard from "../ProductCard";
import ProductDetails from "../ProductDetails";
import { useState } from "react";
import products from '../../data.json';


export default function NewArrivals() {
    const [selectedProduct, setSelectedProduct] = useState(null);


    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const handleCardClick = (product) => {
        setSelectedProduct(product);
    };

    // Get the last four products
    const latestProducts = products.slice(-4);

    return (
        <div className="bg-white container mx-auto py-10">
            <div className="text-center">
                <h2 className="text-black font-bold text-[32px] md:text-[44px] lg:text-[64px] ">New Arrivals</h2>
                <div className="flex justify-center">
                    <p className="max-w-[600px] text-[16px] px-2">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam voluptas tenetur nemo consequuntur veritatis modi. </p>
                </div>
            </div>

            <div className="mx-auto container px-4 py-16 sm:px-6">
                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                    {latestProducts.map((product) => (
                        <ProductCard key={product.id} product={product} onCardClick={handleCardClick} />
                    ))}
                </div>
            </div>

            <div className="flex items-center justify-center mt-8 space-x-4">
                <Link to='/products'>
                    <button onClick={handleClick} className="text-white text-md font-medium bg-black w-48 h-18 rounded-md duration-150 ease-in-out py-3 px-4 mt-3 md:mt-0 hover:bg-gray-600" >See All Products</button>
                </Link>
            </div>

            {/* Pass selected product data to ProductDetails component */}
            {selectedProduct && <ProductDetails product={selectedProduct} />}
        </div>
    );
}
