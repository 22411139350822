import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoCartOutline } from "react-icons/io5";
import Cart from "./Cart"; // Import your Cart component
import { useCart } from "./Context/CartContext";

function Navbar() {


  const { cartItems } = useCart();

  // Calculate total quantity
  const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);



  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const closeCartFunc = () => {
    setIsCartOpen(false);
  };

  return (
    <nav className="bg-white sticky top-0 z-20 duration-300 ease-in-out w-full border-b border-gray-200">
      <div className="container flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/" className="flex items-center space-x-3">
          <img src="/1.png" className="h-10" alt="shuzo Logo" />
        </Link>
        <div className="flex md:order-2 space-x-4 md:space-x-0">
          <div className="relative mt-[10px]">
            <button className="block md:p-0" onClick={toggleCart}>
              <IoCartOutline className="text-black w-7 h-7" />
            </button>
            <div className="absolute -top-2 left-4 bg-red-500 rounded-full w-5 h-5 flex font-bold items-center justify-center text-white text-xs">
              {totalQuantity}
            </div>
          </div>

          <button
            onClick={toggleMenu}
            type="button"
            className="inline-flex mt-[10px] items-center justify-center text-sm text-black rounded-lg md:hidden focus:outline-none focus:ring-none focus:ring-none"
            aria-expanded={isMenuOpen ? "true" : "false"}
          >
            <span className="sr-only">
              {isMenuOpen ? "Close main menu" : "Open main menu"}
            </span>
            {isMenuOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-7 h-7 text-red-400"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-7 h-7 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            )}
          </button>
        </div>
        <div
          className={`items-center justify-between w-full  md:flex md:w-auto mt-[10px] md:order-1 ${isMenuOpen ? "" : "hidden"
            }`}
        >
          <ul className="flex flex-col md:-ml-14 p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 md:flex-row md:mt-0 md:border-0 md:bg-white">
            <li>
              <Link
                to="/"
                className="block py-2 px-3  rounded  md:p-0"
                aria-current="page"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="block py-2 px-3 text-gray-900 rounded  md:p-0"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/products"
                className="block py-2 px-3 text-gray-900 rounded  md:p-0"
              >
                Products
              </Link>
            </li>
            <li>
              <Link
                to="/tracking"
                className="block py-2 px-3 text-gray-900 rounded  md:p-0"
              >
                Order Tracking
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {isCartOpen && <Cart closeCart={closeCartFunc} />}
    </nav>
  );
}

export default Navbar;
