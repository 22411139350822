import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Routers from '../../routes/Routers'
import { useLocation } from 'react-router-dom'

function Layout() {
    const location = useLocation()

    return (
        <>
            {location.pathname.startsWith('/dashboard') ? <></> : <Navbar />}
            <div>
                <Routers />
            </div>
            <Footer />
        </>
    )
}

export default Layout
