import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../Pages/Home';
import ProductDetailsPage from '../Pages/ProductDetailsPage';
import Products from '../Pages/Products';
import CheckoutPage from '../Pages/CheckoutPage';
import OrderSuccessPage from '../Pages/OrderSuccessPage';
import AdminDashboard from '../Pages/AdminDashboard';
import OrdersPage from '../Pages/OrdersPage';

const Routers = () => {
  return (

    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/products' element={<Products />} />
      <Route path="/products/:productId/:productName"  element={<ProductDetailsPage />} />
      <Route path='/checkout' element={<CheckoutPage />} />
      <Route path='/orderSuccess' element={<OrderSuccessPage />} />
      <Route path='/dashboard' element={<AdminDashboard />} />
      <Route path='/orders' element={<OrdersPage />} />






    </Routes>

  );
};

export default Routers;
