import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import data from "../data.json";
import classNames from "classnames";
import Preloader from "./Preloader";
import { useParams } from "react-router-dom";
import GroupLoader from "./GroupLoader";
import Cart from "./Cart";
import { useCart } from "./Context/CartContext";
import toast from 'react-hot-toast';

const ProductDetails = () => {
  const { productId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const { cartItems, addToCart } = useCart();
  const [productData, setProductData] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [showCart, setShowCart] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [isAddedToCart, setIsAddedToCart] = useState(false);



  useEffect(() => {
    if (Array.isArray(data)) {
      const product = data.find(
        (product) => product.id === parseInt(productId)
      );
      if (product) {
        setProductData(product);
        setSelectedColor(product.colors[0]);
        setSelectedSize(product.sizes.find((size) => size.inStock));
        const isInCart = cartItems.some((item) => item.id === product.id);
        setIsAddedToCart(isInCart);
      }
    }
  }, [productId, cartItems]);

  const handleImageLoad = () => {
    setImagesLoaded(true);
  };

  const handleAddToCart = () => {
    const newItem = {
      id: productData.id,
      name: productData.name,
      color: selectedColor.name,
      size: selectedSize.name,
      price: productData.price,
      imageSrc: productData.images[0].src,
      quantity: 1,
    };
    addToCart(newItem);
    setIsAddedToCart(true);
    toast.success('Item added to Cart')

  };

  const handleSizeChange = (size) => {
    setSelectedSize(size);
    setIsAddedToCart(false);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
    setIsAddedToCart(false);
  };

  if (!productData) {
    return <Preloader />;
  }
  if (!productData) {
    return (
      <div className="bg-white">
        {showCart && <Cart products={cartItems} />}
        <div className="container mx-auto mt-6 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:px-8">
          <div className="aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block">
            <img
              src={productData.images[0].src}
              alt={productData.images[0].alt}
              className="h-full w-full object-cover object-center"
              onLoad={handleImageLoad}
            />
          </div>
          {!imagesLoaded && (
            <div className='flex justify-center items-center w-full'>
              <div className=" "><GroupLoader /></div>
            </div>)}
          <div className="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
            {productData.images.slice(1, 3).map((image, index) => (
              <div key={index} className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
                <img
                  src={image.src}
                  alt={image.alt}
                  className="h-full w-full object-cover object-center"
                  onLoad={handleImageLoad}
                />
              </div>
            ))}
          </div>
          <div className="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
            <img
              src={productData.images[3].src}
              alt={productData.images[3].alt}
              className="h-full w-full object-cover object-center"
              onLoad={handleImageLoad}
            />
          </div>
        </div>
        <div className="mx-auto container  px-4 pb-16 pt-10 sm:px-6 lg:grid  lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
          <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{productData.name}</h1>
          </div>
          <div className="mt-4 lg:row-span-3 lg:mt-0">
            <h2 className="sr-only">Product information</h2>
            <p className="text-3xl tracking-tight text-gray-900">PKR {productData.price}</p>
            <div className="mt-10">
              <h3 className="text-sm font-medium text-gray-900">Color</h3>
              <RadioGroup value={selectedColor} onChange={handleColorChange} className="mt-4">
                <RadioGroup.Label className="sr-only">Choose a color</RadioGroup.Label>
                <div className="flex items-center space-x-3">
                  {productData.colors.map((color) => (
                    <RadioGroup.Option
                      key={color.name}
                      value={color}
                      className={({ active, checked }) =>
                        classNames(
                          'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none',
                          active ? `ring-none ${color.hex} ring-offset-none` : '',
                          checked ? `ring-2 ${color.hex} ring-black` : ''
                        )
                      }
                    >
                      <RadioGroup.Label as="span" className="sr-only">
                        {color.name}
                      </RadioGroup.Label>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          'h-8 w-8 rounded-full border border-black border-opacity-10',
                          color.hex // This should apply the color dynamically based on the hex value
                        )}
                        style={{ backgroundColor: color.hex }} // Apply the background color dynamically
                      />
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
            <div className="mt-10">
              <RadioGroup value={selectedSize} onChange={handleSizeChange} className="mt-4">
                <RadioGroup.Label className="sr-only">Choose a size</RadioGroup.Label>
                <div className="grid grid-cols-4 gap-4 sm:grid-cols-8 lg:grid-cols-4">
                  {productData.sizes.map((size) => (
                    <RadioGroup.Option
                      key={size.name}
                      value={size}
                      disabled={!size.inStock}
                      className={({ active }) =>
                        classNames(
                          size.inStock
                            ? 'cursor-pointer bg-white text-gray-900 shadow-sm'
                            : 'cursor-not-allowed bg-gray-50 text-gray-200',
                          active ? 'ring-2 ring-black' : '',
                          'group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6'
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <RadioGroup.Label as="span">{size.name}</RadioGroup.Label>
                          {size.inStock ? (
                            <span
                              className={classNames(
                                active ? 'border' : 'border-2',
                                checked ? 'border-black' : 'border-transparent',
                                'pointer-events-none absolute -inset-px rounded-md'
                              )}
                              aria-hidden="true"
                            />
                          ) : (
                            <span
                              aria-hidden="true"
                              className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                            >
                              <svg
                                className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="none"
                                stroke="currentColor"
                              >
                                <line x1={0} y1={100} x2={100} y2={0} vectorEffect="non-scaling-stroke" />
                              </svg>
                            </span>
                          )}
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
            <button
              type="submit"
              className={classNames(
                'mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-black px-8 py-3 text-base font-medium text-white focus:outline-none focus:ring-none focus:ring-none focus:ring-offset-none',
                { 'opacity-50 cursor-not-allowed': isAddedToCart || !selectedColor || !selectedSize }
              )}
              onClick={handleAddToCart}
              disabled={isAddedToCart}
            >
              {isAddedToCart ? 'Item Added' : 'Add to Cart'}
            </button>

          </div>
          <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
            <div>
              <h3 className="sr-only">Description</h3>
              <div className="space-y-6">
                <p className="text-base text-gray-900">{productData.description}</p>
              </div>
            </div>
            <div className="mt-10">
              <h3 className="text-sm font-medium text-gray-900">Highlights</h3>
              <div className="mt-4">
                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                  {productData.highlights.map((highlight, index) => (
                    <li key={index} className="text-gray-400">
                      <span className="text-gray-600">{highlight}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="mt-10">
              <h2 className="text-sm font-medium text-gray-900">Details</h2>
              <div className="mt-4 space-y-6">
                <p className="text-sm text-gray-600">{productData.details}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white">
      <div className="container mx-auto mt-6 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:px-8">
        <div className="aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block">
          <img
            src={productData.images[0].src}
            alt={productData.images[0].alt}
            className="h-full w-full object-cover object-center"
            onLoad={handleImageLoad}
          />
        </div>
        {!imagesLoaded && (
          <div className="flex justify-center items-center w-full">
            <div className=" ">
              <GroupLoader />
            </div>
          </div>
        )}
        <div className="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
          {productData.images.slice(1, 3).map((image, index) => (
            <div
              key={index}
              className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg"
            >
              <img
                src={image.src}
                alt={image.alt}
                className="h-full w-full object-cover object-center"
                onLoad={handleImageLoad}
              />
            </div>
          ))}
        </div>
        <div className="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
          <img
            src={productData.images[3].src}
            alt={productData.images[3].alt}
            className="h-full w-full object-cover object-center"
            onLoad={handleImageLoad}
          />
        </div>
      </div>
      <div className="mx-auto container  px-4 pb-16 pt-10 sm:px-6 lg:grid  lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
        <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            {productData.name}
          </h1>
        </div>
        <div className="mt-4 lg:row-span-3 lg:mt-0">
          <h2 className="sr-only">Product information</h2>
          <p className="text-3xl tracking-tight text-gray-900">
            PKR {productData.price}
          </p>
          <div className="mt-10">
            <h3 className="text-sm font-medium text-gray-900">Color</h3>
            <RadioGroup
              value={selectedColor}
              onChange={handleColorChange}
              className="mt-4"
            >
              <RadioGroup.Label className="sr-only">
                Choose a color
              </RadioGroup.Label>
              <div className="flex items-center space-x-3">
                {productData.colors.map((color) => (
                  <RadioGroup.Option
                    key={color.name}
                    value={color}
                    className={({ active, checked }) =>
                      classNames(
                        "relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none",
                        active ? `ring-none ${color.hex} ring-offset-none` : "",
                        checked ? `ring-2 ${color.hex} ring-black` : ""
                      )
                    }
                  >
                    <RadioGroup.Label as="span" className="sr-only">
                      {color.name}
                    </RadioGroup.Label>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        "h-8 w-8 rounded-full border border-black border-opacity-10",
                        color.hex // This should apply the color dynamically based on the hex value
                      )}
                      style={{ backgroundColor: color.hex }} // Apply the background color dynamically
                    />
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
          <div className="mt-10">
            <RadioGroup
              value={selectedSize}
              onChange={handleSizeChange}
              className="mt-4"
            >
              <RadioGroup.Label className="sr-only">
                Choose a size
              </RadioGroup.Label>
              <div className="grid grid-cols-4 gap-4 sm:grid-cols-8 lg:grid-cols-4">
                {productData.sizes.map((size) => (
                  <RadioGroup.Option
                    key={size.name}
                    value={size}
                    disabled={!size.inStock}
                    className={({ active }) =>
                      classNames(
                        size.inStock
                          ? "cursor-pointer bg-white text-gray-900 shadow-sm"
                          : "cursor-not-allowed bg-gray-50 text-gray-200",
                        active ? "ring-2 ring-black" : "",
                        "group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6"
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <RadioGroup.Label as="span">
                          {size.name}
                        </RadioGroup.Label>
                        {size.inStock ? (
                          <span
                            className={classNames(
                              active ? "border" : "border-2",
                              checked ? "border-black" : "border-transparent",
                              "pointer-events-none absolute -inset-px rounded-md"
                            )}
                            aria-hidden="true"
                          />
                        ) : (
                          <span
                            aria-hidden="true"
                            className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                          >
                            <svg
                              className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                              viewBox="0 0 100 100"
                              preserveAspectRatio="none"
                              stroke="currentColor"
                            >
                              <line
                                x1={0}
                                y1={100}
                                x2={100}
                                y2={0}
                                vectorEffect="non-scaling-stroke"
                              />
                            </svg>
                          </span>
                        )}
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
          <button
            type="submit"
            className={classNames(
              "mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-black px-8 py-3 text-base font-medium text-white focus:outline-none focus:ring-none focus:ring-none focus:ring-offset-none",
              {
                "opacity-50 cursor-not-allowed":
                  isAddedToCart || !selectedColor || !selectedSize,
              }
            )}
            onClick={handleAddToCart}
            disabled={isAddedToCart || !selectedColor || !selectedSize}
          >
            {isAddedToCart ? "Item Added" : "Add to Cart"}
          </button>
        </div>
        <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
          <div>
            <h3 className="sr-only">Description</h3>
            <div className="space-y-6">
              <p className="text-base text-gray-900">
                {productData.description}
              </p>
            </div>
          </div>
          <div className="mt-10">
            <h3 className="text-sm font-medium text-gray-900">Highlights</h3>
            <div className="mt-4">
              <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                {productData.highlights.map((highlight, index) => (
                  <li key={index} className="text-gray-400">
                    <span className="text-gray-600">{highlight}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mt-10">
            <h2 className="text-sm font-medium text-gray-900">Details</h2>
            <div className="mt-4 space-y-6">
              <p className="text-sm text-gray-600">{productData.details}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
