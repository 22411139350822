import React from 'react'

function Dashboard() {
    return (
        <div>
            Dashboard
        </div>
    )
}

export default Dashboard
