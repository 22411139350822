import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { useCart } from '../Context/CartContext';
import { firestore, FieldValue } from '../../firebaseConfig'; // Importing firestore and FieldValue
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";

function Checkout() {

    const countries = [
        'Pakistan',
    ];

    const navigate = useNavigate();

    const { cartItems, removeFromCart } = useCart();
    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        address: '',
        apartment: '',
        city: '',
        country: '',
        stateProvince: '',
        postalCode: '',
        phone: '',
        deliveryNote: ''
    });


    // Function to generate a random order ID
    const generateOrderId = () => {
        const orderId = Math.floor(100000 + Math.random() * 900000); // Generate a random number between 100000 and 999999
        return orderId.toString();
    };





    const placeOrder = async (products, totalAmount) => {
        if (cartItems.length === 0) {
            toast.error('Your cart is empty. Please add products before placing an order.', {
                duration: 4000 // Adjust duration as needed
            });
            return;
        }

        // Check if all required fields are selected
        if (
            !formData.firstName ||
            !formData.lastName ||
            !formData.address ||
            !formData.city ||
            !formData.country ||
            !formData.stateProvince ||
            !formData.postalCode
        ) {
            // If any required field is missing, display an error toast
            toast.error('Please fill out all required fields.');
            return;
        }

        // Get the selected payment method
        const paymentMethod = document.querySelector('input[name="paymentMethod"]:checked');
        if (!paymentMethod) {
            // If Cash on Delivery is not selected, display an error toast
            toast.error('Please select Cash on Delivery as payment method.');
            return;
        }


        const emailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
        if (!emailRegex.test(formData.email)) {
            toast.error('Please enter a valid Gmail address.');
            return;
        }
    
        // Validate phone number format (Pakistani number)
        const phoneRegex = /^(?:\+92|0)?[0-9]{10}$/;
        if (!phoneRegex.test(formData.phone)) {
            toast.error('Please enter a valid Pakistani phone number.');
            return;
        }



        // Generate a unique order ID
        const orderId = generateOrderId();

        const orderData = {
            orderId: orderId,
            contactInformation: {
                email: formData.email,
                phone: formData.phone,
            },
            shippingInformation: {
                firstName: formData.firstName,
                lastName: formData.lastName,
                address: formData.address,
                apartment: formData.apartment,
                city: formData.city,
                country: formData.country,
                stateProvince: formData.stateProvince,
                postalCode: formData.postalCode,
            },
            products: cartItems.map(item => ({
                name: item.name,
                size: item.size,
                color: item.color,
                price: item.price,
                // Add more fields as needed
            })),

            totalPrice: totalPrice + 200, // Assuming shipping cost is 200 PKR
            deliveryNote: formData.deliveryNote,
            paymentMethod: "Cash on Delivery",
            status: "placed",
            createdAt: new Date(), 
        };

        // Show loading toast
        const promise = firestore.collection('orders').add(orderData);
        toast.promise(
            promise,
            {
                loading: 'Placing order...',
                success: <b>Order placed successfully!</b>,
                error: <b>Error placing order.</b>,
            }
        );

        try {
            await promise;
            // Order placed successfully, navigate to OrderSuccess page
            navigate('/orderSuccess', { state: { orderId, totalAmount, cartItems } });
        } catch (error) {
            console.error('Error placing order:', error);
            // Handle error
        }
    };



    const totalPrice = cartItems.reduce(
        (acc, item) => acc + parseFloat(item.price.replace("PKR", "")),
        0
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleCreditCardClick = () => {
        toast.error('Credit card payment option is not available yet!', {

        });
    };

    return (
        <div className='max-w-[1200px] mx-auto px-8 my-10 md:my-14 '>
            <div className='grid grid-cols-12 gap-4 '>
                <div className='col-span-12 md:col-span-6'>
                    <div className=''>
                        <h3 className='font-bold text-xl text-gray-700'>Contact information</h3>
                        <div className="my-10">
                            <label
                                className="block my-1 text-sm font-medium text-gray-900"
                            >
                                Email address
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                required
                                value={formData.email}
                                onChange={handleChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder='Email address'
                                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                                title="Please enter a valid email address"
                            />

                        </div>
                        <hr className="my-6 border sm:mx-auto lg:my-8" />



                        <div className='grid grid-cols-12 gap-4'>
                            <div className='col-span-12'>
                                <h3 className='font-bold text-xl text-gray-700'>Shipping information</h3>

                            </div>
                            <div className='col-span-6'>

                                <div className="my-1">
                                    <label
                                        className="block my-1 text-sm font-medium text-gray-900"
                                    >
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        required
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder='First name'
                                    />
                                </div>

                            </div>

                            <div className='col-span-6'>
                                <div className="my-1">
                                    <label
                                        className="block my-1 text-sm font-medium text-gray-900"
                                    >
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        required
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder='Last name'
                                    />
                                </div>

                            </div>

                            <div className='col-span-12'>
                                <div className="my-1">
                                    <label
                                        className="block my-1 text-sm font-medium text-gray-900"
                                    >
                                        Address
                                    </label>
                                    <input
                                        type="text"
                                        id="address"
                                        name="address"
                                        required
                                        value={formData.address}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder=''
                                    />
                                </div>

                            </div>

                            <div className='col-span-12'>
                                <div className="my-1">
                                    <label
                                        className="block my-1 text-sm font-medium text-gray-900"
                                    >
                                        Apartment, suite, etc.

                                    </label>
                                    <input
                                        type="text"
                                        id="apartment"
                                        name="apartment"
                                        required
                                        value={formData.apartment}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder=''
                                    />
                                </div>

                            </div>

                            <div className='col-span-6'>
                                <div className="my-1">
                                    <label
                                        className="block my-1 text-sm font-medium text-gray-900"
                                    >
                                        City

                                    </label>
                                    <input
                                        type="text"
                                        id="city"
                                        name="city"
                                        required
                                        value={formData.city}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder='City'
                                    />
                                </div>

                            </div>

                            <div className='col-span-6'>
                                <div className="my-1">
                                    <label className="block my-1 text-sm font-medium text-gray-900">
                                        Country
                                    </label>
                                    <select
                                        id="country"
                                        name="country"
                                        required
                                        value={formData.country}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 appearance-none"
                                        style={{
                                            WebkitAppearance: 'none', // Remove default iOS styling
                                            MozAppearance: 'none', // Remove default Firefox styling
                                            appearance: 'none', // Remove default browser styling
                                            paddingLeft: '0.75rem', // Adjust padding for consistency
                                            paddingRight: '2.5rem', // Adjust padding for consistency
                                            backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5 text-gray-500 absolute right-0 top-0 mt-2 mr-2' viewBox='0 0 20 20' fill='none' stroke='currentColor'%3e%3cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7 7l3-3 3 3m0 6l-3 3-3-3' /%3e%3c/svg%3e")`, // Add custom arrow icon
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'right 0.5rem center',
                                            backgroundSize: '1.5em 1.5em',
                                        }}
                                    >
                                        <option value="">Select a country</option>
                                        {countries.map((country, index) => (
                                            <option key={index} value={country}>
                                                {country}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                            </div>

                            <div className='col-span-6'>
                                <div className="my-1">
                                    <label
                                        className="block my-1 text-sm font-medium text-gray-900"
                                    >
                                        State/Province
                                    </label>
                                    <input
                                        type="text"
                                        id="stateProvince"
                                        name="stateProvince"
                                        required
                                        value={formData.stateProvince}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder='State/Province
                                        '
                                    />
                                </div>

                            </div>

                            <div className='col-span-6'>
                                <div className="my-1">
                                    <label
                                        className="block my-1 text-sm font-medium text-gray-900"
                                    >

                                        Postal code

                                    </label>
                                    <input
                                        type="text"
                                        id="postalCode"
                                        name="postalCode"
                                        value={formData.postalCode}
                                        onChange={handleChange}
                                        required
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder='
                                        Postal code'
                                    />
                                </div>

                            </div>
                            <div className='col-span-12'>
                                <div className="my-1">
                                    <label
                                        className="block my-1 text-sm font-medium text-gray-900"
                                    >
                                        Phone
                                    </label>
                                    <input
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        value={formData.phone}
                                        required
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder='Phone'
                                    />
                                </div>

                            </div>

                            <div className='col-span-12'>
                                <div className="my-1">
                                    <label
                                        className="block my-1 text-sm font-medium text-gray-900"
                                    >
                                        Note for delivery rider (optional)
                                    </label>
                                    <textarea
                                        rows={6}
                                        type="text"
                                        id="deliveryNote"
                                        name="deliveryNote"
                                        value={formData.deliveryNote}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder='your note ........'
                                    />
                                </div>

                            </div>

                            <div className='col-span-12'>
                                <hr className="my-4 border sm:mx-auto lg:my-4" />
                            </div>
                            <div className='col-span-12'>
                                <h3 className='font-bold text-xl text-gray-700'>Payment method</h3>

                            </div>
                            <div className='col-span-6'>
                                <label>
                                    <input
                                        type="radio"
                                        defaultValue={0} // Use 0 for Cash on Delivery
                                        required
                                        className="peer hidden"
                                        name="paymentMethod" // Use the same name for both radio buttons
                                    />
                                    <div className="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-black">
                                        <h2 className="font-medium text-gray-700">Cash on Delivery</h2>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="w-9 h-9 text-black invisible group-[.peer:checked+&]:visible"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                    </div>
                                </label>
                            </div>

                            <div className='col-span-6'>
                                <label>
                                    <input
                                        type="radio"
                                        defaultValue={1}
                                        className="peer hidden"
                                        name="paymentMethod" // Use the same name for both radio buttons
                                        onClick={handleCreditCardClick}
                                    />
                                    <div className="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-black">
                                        <h2 className="font-medium text-gray-700">Credit card</h2>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="w-9 h-9 text-black invisible group-[.peer:checked+&]:visible"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                    </div>
                                </label>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='col-span-12 md:col-span-6'>



                    <div className='md:px-4'>
                        <h3 className='font-bold text-xl text-gray-700'>Order summary</h3>

                        <div>
                            <div className="mt-8 max-h-[500px] overflow-y-auto md:px-4">
                                <div className="flow-root">
                                    <ul
                                        role="list"
                                        className="-my-6 divide-y divide-gray-200"
                                    >
                                        {cartItems.map((product) => (
                                            <li key={product.id} className="flex py-6">
                                                <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                                    <img
                                                        src={product.imageSrc}
                                                        alt={product.imageAlt}
                                                        className="h-full w-full object-cover object-center"
                                                    />
                                                </div>

                                                <div className="ml-4 flex flex-1 flex-col">
                                                    <div>
                                                        <div className="flex justify-between text-base font-medium text-gray-900">
                                                            <h3>
                                                                <a href={product.href}>
                                                                    {product.name}
                                                                </a>
                                                            </h3>
                                                            <p className="ml-4">{product.price} PKR</p>
                                                        </div>
                                                        <p className="mt-1 text-sm text-gray-500">
                                                            {product.color}
                                                        </p>
                                                        <p className="mt-1 text-sm text-gray-500">
                                                            {product.size}
                                                        </p>
                                                    </div>
                                                    <div className="flex flex-1 items-end justify-between text-sm">
                                                        <p className="text-gray-500">
                                                            Qty {product.quantity}
                                                        </p>

                                                        <div className="flex">
                                                            <button
                                                                type="button"
                                                                className="font-medium text-red-400 hover:text-red-600"
                                                                onClick={() =>
                                                                    removeFromCart(product.id)
                                                                }
                                                            >
                                                                Remove
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <hr className="my-6 border sm:mx-auto lg:my-8" />

                            <div className="flex justify-between text-base font-medium text-gray-900">
                                <p>Subtotal</p>
                                <p>{totalPrice} PKR</p>
                            </div>

                            <div className="flex justify-between text-base font-medium text-gray-900 mt-5">
                                <p>Shipping</p>
                                <p>200 PKR</p>
                            </div>
                            <hr className="my-6 border sm:mx-auto lg:my-8" />
                            <div className="flex justify-between text-xl font-bold text-gray-900 mt-10">
                                <p>Total</p>
                                <p>{(totalPrice + 200)} PKR</p>
                            </div>


                            <div className=' mt-10'>
                                <button onClick={placeOrder} className="text-white text-md font-medium bg-black w-full rounded-md duration-150 ease-in-out py-3 px-4  md:mt-0 hover:bg-gray-600" >Place Order</button>
                            </div>
                        </div>
                    </div>



                </div>

            </div>
        </div>
    )
}

export default Checkout
