import React, { useState, useEffect } from "react";
import "./App.css";
import Layout from "./Components/Layout/Layout";
import Preloader from "./Components/Preloader";
import { CartProvider } from "./Components/Context/CartContext";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Toaster } from 'react-hot-toast';

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Simulate data loading or any other asynchronous task
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Change the timeout value as needed
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <CartProvider>
          <SpeedInsights />
          <Toaster
            position="top-center"
            reverseOrder={false}
          />
          <Layout />
        </CartProvider>
      )}
    </>
  );
}

export default App;
