import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SkeletonLoader from './SkeletonLoader';
import { TbEye } from "react-icons/tb";

const ProductCard = ({ product, onCardClick }) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const handleClick = () => {
        if (product.status === 'inStock') {
            onCardClick(product);
        }
    };



    return (
        <div className="">
            <Link

                className={`group relative ${product.status === 'outOfStock' ? 'opacity-50 pointer-events-none' : ''}`}
            >
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-black xl:aspect-h-8 xl:aspect-w-7 relative">
                    {!imageLoaded && <SkeletonLoader />} {/* Conditionally render SkeletonLoader */}
                    <img
                        src={product.imageSrc}
                        alt={product.imageAlt}
                        className={`h-[400px] w-full cursor-pointer duration-150 ease-in-out object-cover object-center group-hover:opacity-75 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
                        onLoad={handleImageLoad}
                    />

                    {product.percentageOff !== 0 && (
                        <div className="absolute top-2 right-2 bg-red-500 text-white font-bold px-2 py-1 rounded">
                            {product.percentageOff}% Off
                        </div>
                    )}


                    <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        <Link
                            to={`/products/${product.id}/${encodeURIComponent(product.name)}`}
                            onClick={handleClick}
                            key={product.id}
                            className="bg-white py-2 px-2 rounded-full"

                        >
                            <TbEye className='text-black w-6 h-6' />

                        </Link>
                    </div>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <h3 className="font-bold text-lg text-gray-700 cursor-pointer">{product.name}</h3>
                    <p className="text-lg font-medium text-gray-900">PKR {product.price}</p>
                </div>
            </Link>
            <>
                <div className='flex justify-between items-center'>
                    <p className="mt-1 text-sm text-gray-600 font-medium">{product.category}</p>
                    {product.status === 'inStock' ? (
                        <span className="text-green-500 font-bold">In Stock</span>
                    ) : (
                        <span className="text-red-500 font-bold">Out of Stock</span>
                    )}
                </div>
            </>
        </div>
    );
};

export default ProductCard;
