// Preloader.js
import React from 'react';
import { MoonLoader } from 'react-spinners';



const Preloader = () => {
    return (
        <div className="preloader">
            <MoonLoader color="#000" />
        </div>
    );
};

export default Preloader;
