import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../Components/Home/Banner';
import NewArrivals from '../Components/Home/NewArrivals';

function Home() {
    return (
        <div>
            <Helmet>
                <title> Home | Shuzo  </title>
                <meta name="description" content="Description of your website" />
                {/* Add more meta tags as needed */}
            </Helmet>
            <Banner />
            <NewArrivals />
        </div>
    );
}

export default Home;
